import * as React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Content from '../components/Content/Content'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import * as BlockStyles from '../components/Blocks/Blocks.module.scss'

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>Page not Found | 404 | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Content alignCenter={true}>
        <h1>Whoops!</h1>
        <p>
          Um, yeah, this is awkward. We couldn’t find the page you were looking
          for - it may have been moved or deleted. How about heading back to
          our homepage? You’re most likely to find it there.
        </p>
        <p>
          <a className={BlockStyles.Button} href={`/`}>
            Head Back To Our Homepage
          </a>
        </p>
      </Content>
      <Footer />
    </>
  )
}

export default PageNotFound
